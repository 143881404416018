<template>
  <div class="events-page w-100">
    <EventList class="pb-2 pb-xxl-5" />
    <SectionPhone class="mt-5 pb-3 pt-3 pt-md-5 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import EventList from "./EventList";
import SectionPhone from "@/views/Landing/SectionPhone";
import storage from "@/utils/storage";

export default {
  name: "Events",
  title: "events",
  components: {
    EventList,
    SectionPhone
  },
  data() {
    return {
      isHistorySearch: false
    }
  },
  beforeRouteEnter(to, from, next) {
    if(!from.name || from.name !== 'eventDetail') {
      storage.remove('search')
    }
    next();
  },
};
</script>