<template>
  <div class="section-content section-pickup pt-4 w-100">
    <b-container class="container-xlg pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Events"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="大会情報"
        />
        <b-button
          class="d-block d-md-none btn-toggle mb-2"
          v-b-toggle.event-filter
          >絞り込む</b-button
        >
        <b-collapse id="event-filter" class="w-100 d-md-block">
          <b-row
            class="filter-search justify-content-end"
            cols="1"
            cols-sm="2"
            cols-md="3"
            cols-xl="4"
            align-v="stretch"
          >
            <b-col>
              <b-dropdown
                size="sm"
                variant="outline-secondary"
                block
                :menu-class="[
                  'w-100',
                  isAnimatedMenu
                    ? 'animate__animated animate__fadeIn'
                    : 'animate__animated animate__fadeOut',
                ]"
                @show="showDropdownMenu"
                @hide="hideDropdownMenu"
                class="dropdown-search mb-1 mb-md-3"
              >
                <template #button-content>
                  <span
                    class="text-truncate selected-name"
                    :title="selected_name"
                    >{{ selected_name || "ゲームタイトル" }}</span
                  >
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group class="mb-0" label-size="sm">
                    <b-form-input
                      v-model="search_name_game"
                      id="tag-search-input"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <li class="dropdown-events">
                  <ul class="list-group list-unstyled">
                    <b-dropdown-item-button
                      @click="
                        onOptionClick({ value: null, text: 'ゲームタイトル' })
                      "
                      class="text-truncate"
                    >
                      <span class="text-truncate d-block selected-name"
                        >ゲームタイトル</span
                      >
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-for="(option, index) in availableOptions"
                      :key="index"
                      @click="onOptionClick({ option })"
                      class="text-truncate"
                    >
                      <span
                        :title="option.text"
                        class="text-truncate d-block selected-name"
                        >{{ option.text }}</span
                      >
                    </b-dropdown-item-button>
                  </ul>
                </li>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  該当ゲームタイトルがありません。
                </b-dropdown-text>
              </b-dropdown>
            </b-col>
            <b-col>
              <b-input-group class="mb-1 mb-md-3">
                <b-form-input
                  v-model="search.startDate"
                  type="text"
                  placeholder="開始期間"
                  class="text-truncate"
                  disabled
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    :hide-header="true"
                    label-help=""
                    v-model="search.startDate"
                    @input="startDateSelected"
                    locale="ja"
                    button-only
                    right
                  >
                    <template #button-content>
                      <esports-calendar-date />
                    </template>
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group class="mb-1 mb-md-3">
                <b-form-input
                  v-model="search.endtDate"
                  type="text"
                  placeholder="終了期間"
                  class="text-truncate"
                  disabled
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    :hide-header="true"
                    locale="ja"
                    label-help=""
                    v-model="search.endtDate"
                    @input="endDateSelected"
                    button-only
                    right
                  >
                    <template #button-content>
                      <esports-calendar-date />
                    </template>
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <b-form-select
                v-model="search.selected_status"
                :options="options_status"
                class="mb-1 mb-md-3 text-truncate"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >ステータス</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                v-model="search.selected_sort"
                :options="options_sort"
                class="mb-1 mb-md-3 text-truncate"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >並べ替え</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-input
                class="name-event mb-2 mb-md-3 text-truncate"
                v-model="search.freeword"
                placeholder="フリーワード"
              ></b-form-input>
            </b-col>
            <b-col>
              <b-button class="primary mb-4 mb-md-3" :disabled="isSubmit" @click="searchEvent" block
                >検索</b-button
              >
            </b-col>
          </b-row>
        </b-collapse>
      </div>
      <div
        class="
          limit-event
          filter-search
          d-flex
          justify-content-between
          mt-0
          mb-1
          mt-md-3
          mb-md-0
        "
        v-if="isResult"
      >
        <span class="txt-lable">{{ rows }}件中{{ rowsVisible }}件表示</span>
        <b-form-select
          v-model="perPage"
          :options="options_limit"
          class="mb-3 text-truncate select-limit"
        ></b-form-select>
      </div>
      <esports-pickup-loading v-if="isLoading" :m_col="1" :events="6" />
      <b-row v-else cols="1" class="mt-3" cols-md="3" align-v="stretch">
        <b-col v-for="(event, index) in ListEvent" :key="index">
          <b-card
            class="
              mb-4 mb-md-3 mb-lg-5
              border-0
              rounded-0
              position-relative
              card-stagger
            "
            no-body
          >
            <div
              class="img-thumb"
              @click="redirectDetail($router, event)"
              role="button"
            >
              <b-card-img-lazy
                :src="event.picture"
                lazy="loading"
                alt="Image"
                class="rounded-0 img-event"
                v-bind="mainProps('#fff')"
                show
              ></b-card-img-lazy>
            </div>
            <div class="article-content">
              <div class="d-flex">
                <span class="top-title" v-if="event.onlineFlag"
                  >オンライン</span
                >
                <span class="top-title box-black" v-else>{{
                  event.venue || "オフライン"
                }}</span>
              </div>

              <b-link :href="`/events/detail/${event.eventId}`">
                <h4>
                  {{ event.title }}
                </h4>
              </b-link>
              <b-card-header v-if="event.startDate" class="pl-0 pb-0">
                <span class="date">{{ event.startDate }}</span>
              </b-card-header>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <esports-not-result v-if="hideBeforeLoading && !isResult">
        <span role="button" @click="resetFilter" class="ext-reset"
          >リセット</span
        >
      </esports-not-result>
      <div
        class="
          limit-event
          filter-search
          d-flex
          justify-content-between
          mt-0
          mb-1
          mt-md-0
          mb-md-0
        "
        v-if="isResult"
      >
        <span class="txt-lable">{{ rows }}件中{{ rowsVisible }}件表示</span>
        <b-form-select
          v-model="perPage"
          :options="options_limit"
          class="mb-3 text-truncate select-limit"
        ></b-form-select>
      </div>
      <b-pagination
        v-if="isPaging"
        v-model="page"
        :total-rows="rows"
        :per-page="perPage"
        size="md"
        align="center"
        class="page-nav my-3"
      >
        <template #first-text="{ disabled }">
          <esports-icon-double-arrow-left v-if="disabled" />
          <esports-icon-double-arrow-left v-else color="#231F20" />
        </template>
        <template #prev-text="{ disabled }">
          <esports-icon-arrow-left v-if="disabled" />
          <esports-icon-arrow-left v-else color="#231F20" />
        </template>

        <template #last-text="{ disabled }">
          <esports-icon-double-arrow-right v-if="disabled" />
          <esports-icon-double-arrow-right v-else color="#231F20" />
        </template>
        <template #next-text="{ disabled }">
          <esports-icon-arrow-right v-if="disabled" />
          <esports-icon-arrow-right v-else color="#231F20" />
        </template>
      </b-pagination>
      <esports-loading-button
        class="overlay-content-page"
        width="100"
        height="100"
        v-if="isLoading"
      />
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import storage from "@/utils/storage";

export default {
  name: "EventList",
  data() {
    return {
      ListEvent: [],
      limit: true,
      isLoading: false,
      options_status: [
        { value: true, text: "終了" },
        { value: false, text: "未完了" },
      ],
      options_sort: [
        { value: 1, text: "最も近い開始日順" },
        { value: 2, text: "人気順" },
        { value: 3, text: "賞金額の高い順" },
        { value: 4, text: "投票数の多い順" },
      ],
      options_limit: [
        {
          value: 10,
          text: "10件",
        },
        {
          value: 20,
          text: "20件",
        },
        {
          value: 40,
          text: "40件",
        },
        {
          value: 60,
          text: "60件",
        },
        {
          value: 70,
          text: "70件",
        },
        {
          value: 100,
          text: "100件",
        },
      ],
      options_name: [],
      selected_name: null,
      page: 1,
      rows: 1,
      perPage: 20,
      search_name_game: "",
      search: {
        freeword: "",
        selected_sort: 1,
        gameId: "",
        startDate: "",
        endtDate: "",
        selected_status: null,
      },
      searchTemp: null,
      currentPage: 1,
      isReset: false,
      isSubmit: false
    };
  },
  watch: {
    perPage(nVal) {
      this.page = 1;
      this.currentPage = 1;
      if (this.isReset) return;

      this.handleFilterEvents(this.page);
    },
    page(nVal) {
      if (this.page === this.currentPage) {
        this.currentPage = 1;
        return;
      }

      this.currentPage = nVal;

      this.handleFilterEvents(this.page);
    },
  },
  computed: {
    criteria() {
      return this.search_name_game.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options_name;

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.text.toLowerCase().indexOf(criteria) > -1
        );
      }

      // Show all options available
      return options;
    },
    isPaging() {
      return this.rows > this.perPage;
    },
    isResult() {
      return this.ListEvent.length;
    },
    type_event() {
      return this.$route.params.type_event || "";
    },
    rowsVisible() {
      if (this.page === 1) {
        return this.ListEvent.length;
      }
      return this.perPage * (this.page - 1) + this.ListEvent.length;
    },
  },
  async mounted() {
    if (storage.get("search")) {
      this.search = JSON.parse(storage.get("search"));
    }

    this.searchTemp = { ...this.search };

    this.isLoading = true;

    let params = {
      perPage: this.perPage,
      order: this.search.selected_sort,
      page: this.page,
      freeword: this.search.freeword,
      gameId: this.search.gameId,
      startPeriod: this.search.startDate,
      endPeriod: this.search.endtDate,
      finished: this.search.selected_status,
    };

    if (!params.freeword) delete params.freeword;
    if (!params.gameId) delete params.gameId;
    if (!params.startPeriod) delete params.startPeriod;
    if (!params.endPeriod) delete params.endPeriod;
    if (params.finished === null) delete params.finished;

    if (this.type_event === "pickup") {
      params.finished = this.search.selected_status = false;
      params.order = this.search.selected_sort = 2;
    } else if (this.type_event === "archive") {
      params.finished = this.search.selected_status = true;
      params.order = this.search.selected_sort = 1;
    }

    if (this.type_event === "detail") {
      this.$router.push({ path: "/events" });
    }

    await Promise.all([this.getGameList(), this.getEventList(params)]);

    let gameSelected = this.options_name.find(
      (item) => item.value === this.search.gameId
    );

    if (gameSelected) this.selected_name = gameSelected.text;

    this.hideBeforeLoading = true;
  },
  methods: {
    async getEventList(params) {
      this.isSubmit = true;
      this.isLoading = true;

      const result = await this.$store
        .dispatch("event/getEventList", params)
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.rows = result.total;

        this.ListEvent = result.data.map((event, index) => {
          event.picture = `${process.env.VUE_APP_BACKEND + event.picture}`;

          if (event.startDate) {
            event.startDate = moment(event.startDate).format("YYYY-MM-DD");
          }
          return event;
        });

        this.isReset = false;

        this.$nextTick(() => {
          this.initStagger();
          this.scrollToTop();
          this.isSubmit = false;
        });
        this.isLoading = false;
      }
    },
    async getGameList() {
      const result = await this.$store
        .dispatch("event/getGameList")
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.options_name = result.games.map((game) => {
          return {
            value: game.gameId,
            text: game.title,
          };
        });
      }
    },
    onOptionClick({ option }) {
      this.search_name_game = "";
      if (!option) {
        this.selected_name = "";
        this.search.gameId = "";
        return;
      }

      this.selected_name = option.text;
      this.search.gameId = option.value;
    },
    startDateSelected(date) {
      if (
        !this.search.endtDate ||
        moment(date).isSameOrAfter(this.search.endtDate, "day")
      ) {
        this.search.endtDate = date;
      }
    },
    endDateSelected(date) {
      if (
        !this.search.startDate ||
        !moment(date).isSameOrAfter(this.search.startDate, "day")
      ) {
        this.search.startDate = date;
      }
    },
    async searchEvent() {
      this.page = 1;
      this.currentPage = 1;
      await this.handleFilterEvents(this.page);
    },
    async handleFilterEvents(page) {
      let params = {
        perPage: this.perPage,
        page: page,
        order: this.search.selected_sort,
        freeword: this.search.freeword,
        gameId: this.search.gameId,
        startPeriod: this.search.startDate,
        endPeriod: this.search.endtDate,
        finished: this.search.selected_status,
      };

      if (!params.freeword) delete params.freeword;
      if (!params.gameId) delete params.gameId;
      if (!params.startPeriod) delete params.startPeriod;
      if (!params.endPeriod) delete params.endPeriod;
      if (params.finished === null) delete params.finished;

      await this.getEventList(params);
    },
    resetData() {
      this.page = 1;
      this.search.freeword = "";
      this.currentPage = 1;
      this.search.gameId = "";
      this.search.startDate = "";
      this.search.endtDate = "";
      this.perPage = 20;
      this.search.selected_sort = 1;
      this.search.selected_status = null;
      this.selected_name = null;
      this.isReset = true;
    },
    async redirectDetail($router, event) {
      // if (
      //   !_.isEqual(this.searchTemp, this.search) && this.ListEvent.length
      // ) {}

      await storage.set("search", JSON.stringify(this.search));
      await this.redirectEventDetail($router, event.eventId);
    },
    async resetFilter() {
      this.resetData();

      if (this.page === this.currentPage && !this.isReset) return;

      await this.getEventList({
        perPage: this.perPage,
        order: this.search.selected_sort,
        page: this.page,
      });
    },
  },
};
</script>